<template>
  <section>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold"
        >Users ({{ listMeta.total }})</v-toolbar-title
      >

      <!-- end toolbar title -->
      <v-spacer />
      <!-- end -->
      <v-row v-show="showSearch" align="center" style="max-width: 370px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name, email or phone number"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-btn class="ml-5" icon @click="showSearchField">
        <v-progress-circular
          indeterminate
          v-if="isLoading"
        ></v-progress-circular>

        <img src="@/assets/icons/search.svg" alt height="17.7px" v-else />
      </v-btn>
    </v-app-bar>
    <!-- end app bar -->

    <div
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="users"
        multi-sort
        hide-default-footer
        disable-pagination
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-desc="sortTable"
      >
        <template v-slot:body="{ items: users }">
          <tr
            v-for="user in users"
            :key="user.id"
            class="clickable"
            @click="
              $router.push({
                name: 'user.details',
                params: { id: user.id },
              })
            "
            height="75px"
          >
            <td>
              <user-avatar
                :avatar="user.avatar"
                size="40"
                class="mr-20"
              ></user-avatar>
            </td>
            <td>{{ user.first_name }}</td>
            <td>{{ user.last_name }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.raw_phone_number }}</td>
            <td>
              {{
                user.organisation ? user.organisation.name : 'No Organisation'
              }}
            </td>
            <td>{{ user.addedDate }}</td>
            <td style="text-align: center">{{ user.impact_points }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <v-speed-dial v-model="fab" id="add-user-menu">
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark large rounded>
          <v-icon>mdi-account-multiple-plus-outline </v-icon>
          <span class="ml-4">Add New User</span>
        </v-btn>
      </template>
      <v-btn
        dark
        color="primary"
        width="180"
        rounded
        @click="importForm = !importForm"
      >
        <v-icon>mdi-microsoft-excel </v-icon>
        <span class="ml-4">Import Users</span>
      </v-btn>
      <v-btn
        dark
        color="primary"
        width="180"
        rounded
        :to="{ name: 'user.new' }"
      >
        <v-icon>mdi-account-plus-outline </v-icon>
        <span class="ml-4">Add User</span>
      </v-btn>
    </v-speed-dial>
    <v-dialog v-model="importForm" width="800" persistent>
      <import-users-form v-if="importForm" @close="importForm = false" />
    </v-dialog>
  </section>
  <!-- end section -->
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import ImportUsersForm from '@/components/ImportUsersForm'
import infiniteScroll from 'vue-infinite-scroll'
import { mapActions, mapMutations, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import { mdiAccountPlus } from '@mdi/js'

export default {
  name: 'UsersPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    UserAvatar,
    ImportUsersForm,
  },

  data() {
    return {
      showSearch: false,
      showForm: false,
      importForm: false,
      isLoading: false,
      fab: false,
      filter: {
        search: '',
      },
      icons: {
        add: mdiAccountPlus,
      },
      sortBy: [],
      sortDesc: [],
      headers: [
        {
          sortable: false,
          width: '5%',
        },
        {
          text: 'First name',
          value: 'first_name',
          sortable: false,
          width: '12%',
        },
        {
          text: 'Last name',
          value: 'last_name',
          sortable: false,
          width: '12%',
        },
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          width: '12%',
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
          width: '12%',
        },
        {
          text: 'Phone number',
          value: 'phone_number',
          sortable: false,
          width: '12%',
        },
        {
          text: 'Organisation',
          value: 'organisation.name',
          sortable: false,
          width: '12%',
        },
        {
          text: 'Added date',
          value: 'addedDate',
          sortable: true,
          width: '12%',
        },
        {
          text: 'Impact points',
          value: 'impact_points',
          sortable: true,
          width: '5%',
        },
      ],
    }
  },

  mounted() {
    this.clearUsers()
    this.fetchUsers()
  },

  computed: {
    ...mapState({
      users: (state) => state.user.list,
      listMeta: (state) => state.user.listMeta,
    }),
  },

  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
    }),

    ...mapMutations({
      clearUsers: 'user/clearUserList',
    }),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },

    async fetchUsers(page = 1) {
      if (!this.isLoading) {
        let params = { page }

        if (this.sortBy) {
          let sort = this.sortBy.map(function (sort, index) {
            if (sort === 'addedDate') {
              sort = 'created_at'
            }

            if (this[index] === true) {
              return '-' + sort
            }

            return sort
          }, this.sortDesc)

          params.sort = sort
        }

        if (this.filter.search) {
          params.search = this.filter.search
        }

        this.isLoading = true
        await this.getUsers(params)
        this.isLoading = false
      }
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    search: debounce(function () {
      this.clearUsers()
      this.fetchUsers()
    }, 600),

    async sortTable() {
      let params = { page: 1 }

      if (this.sortBy) {
        let sort = this.sortBy.map(function (sort, index) {
          if (sort === 'addedDate') {
            sort = 'created_at'
          }

          if (this[index] === true) {
            return '-' + sort
          }

          return sort
        }, this.sortDesc)

        params.sort = sort
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearUsers()

      this.isLoading = true
      await this.getUsers(params)
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
#add-user-menu {
  position: fixed;
  bottom: 64px;
  right: 64px;
}
#add-user-button {
  position: fixed;
  bottom: 64px;
  right: 64px;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
</style>
