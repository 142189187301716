<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title> Import Users </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')"> <v-icon>mdi-close</v-icon> </v-btn>
    </v-toolbar>
    <v-card-text class="mt-8">
      <v-alert
        border="top"
        color="green"
        outlined
        dark
        icon="mdi-check"
        v-if="success"
      >
        Users successfully imported!
        <div class="mt-2 red--text" v-if="errors.length > 0">
          Some users has invalid details, please check the table below.
        </div>
      </v-alert>

      <label class="text-field-label">CSV/Excel File</label>
      <v-file-input
        flat
        solo
        prepend-icon
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        prepend-inner-icon="mdi-paperclip"
        v-model="file"
        class="mt-2"
        :disabled="loading"
      />
      <label class="text-field-label">Organisation</label>
      <v-autocomplete
        class="mt-2"
        flat
        solo
        :items="organisations"
        item-value="id"
        item-text="name"
        v-model="organisation"
        :disabled="loading"
      />
    </v-card-text>
    <v-card-text class="text-center">
      <v-btn color="primary" large @click="submit" :loading="loading">
        Submit
      </v-btn>
    </v-card-text>
    <template v-if="errors.length > 0">
      <v-card-title class="red--text"> Failed Users </v-card-title>
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>Row</th>
              <th>Attribute</th>
              <th>Value</th>
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="error in errors" :key="error.row">
              <td>{{ error.row }}</td>
              <td>{{ error.attribute }}</td>
              <td>
                <div v-for="v in error.values" :key="v">{{ v }}</div>
              </td>
              <td>
                <div v-for="e in error.errors" :key="e">{{ e }}</div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import Organisation from '@/models/Organisation'

export default {
  data() {
    return {
      file: null,
      organisations: [],
      organisation: null,
      loading: false,
      errors: [],
      success: false
    }
  },

  created() {
    this.fetchOrganisations()
  },

  methods: {
    async fetchOrganisations() {
      this.organisations = await Organisation.$get()
    },
    async submit() {
      this.loading = true
      this.success = false
      const form = new FormData()
      form.append('organisation_id', this.organisation)
      form.append('file', this.file)
      try {
        const res = await this.$api.post('/users/import', form)
        if (res.data && res.data.errors) {
          this.errors = res.data.errors
        }
        this.success = true
        this.organisation = null
        this.file = null
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>
